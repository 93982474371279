






























import constants from '@/constants'
import AonButton from '@/components/AonButton.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Auth } from '@/services/Auth'

@Component({
  components: {
    AonButton
  }
})
export default class NotFound extends Vue {
  get loggedIn() {
    return Auth.loggedInAs('otp')
  }

  goHome() {
    if (this.loggedIn) {
      this.$router.replace({ name: constants.routeNames.DASHBOARD })
    } else {
      this.$router.replace({ name: constants.routeNames.HOME })
    }
  }
}
